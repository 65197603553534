import React from 'react';
import { t } from 'i18next';
import Slider from '../utils/slider';

const SecondSlider = () => {
	const sections = [
		{
			id: 'openness_slide',
			title: (
				<div className="slider__item slider__item--second slider__item--open">
					<div className="slider__text-wrapper">
						<h2 className="slider__lead-text big-text">
							{t('lead.open.preTitle')}
						</h2>
						<p
							className="slider__title-text title-text title-text--white"
							dangerouslySetInnerHTML={{ __html: t('lead.open.bigText') }}
						/>
					</div>
				</div>
			),
		},
		{
			id: 'enterprise_slide',
			title: (
				<div className="slider__item slider__item--second slider__item--ip">
					<div className="slider__text-wrapper">
						<h2 className="slider__lead-text big-text">
							{t('lead.ip.preTitle')}
						</h2>
						<p
							className="slider__title-text title-text title-text--white"
							dangerouslySetInnerHTML={{ __html: t('lead.ip.bigText') }}
						/>
					</div>
				</div>
			),
		},
		{
			id: 'creativity_slide',
			title: (
				<div className="slider__item slider__item--second slider__item--creativity">
					<div className="slider__text-wrapper">
						<h2 className="slider__lead-text big-text">
							{t('lead.creativity.preTitle')}
						</h2>
						<p
							className="slider__title-text title-text title-text--white"
							dangerouslySetInnerHTML={{ __html: t('lead.creativity.bigText') }}
						/>
					</div>
				</div>
			),
		},
		{
			id: 'attention_slide',
			title: (
				<div className="slider__item slider__item--second slider__item--attention">
					<div className="slider__text-wrapper">
						<h2 className="slider__lead-text big-text">
							{t('lead.attention.preTitle')}
						</h2>
						<p
							className="slider__title-text title-text title-text--white"
							dangerouslySetInnerHTML={{ __html: t('lead.attention.bigText') }}
						/>
					</div>
				</div>
			),
		},
	];

	return <Slider sections={sections} background="#1d1d1d" />;
};

export default SecondSlider;
