import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	Autoplay,
	EffectCreative,
	Navigation,
	Pagination,
} from 'swiper/modules';

const Slider = ({ sections, background = '#fff' }) => {
	const sliderRef = useRef(null);
	const swiperRef = useRef(null);
	const [isSliderVisible, setIsSliderVisible] = useState(false);

	useEffect(() => {
		const swiperEl = swiperRef.current;

		if (sliderRef.current) {
			const observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting) {
						swiperEl.swiper.autoplay.start();

						if (!isSliderVisible) {
							setIsSliderVisible(true);
							setTimeout(() => {
								window.scrollTo({
									top: entry.target.offsetTop,
									behavior: 'smooth',
								});
							}, 50);
						}
					} else {
						swiperEl.swiper.autoplay.stop();

						if (isSliderVisible) {
							setIsSliderVisible(false);
						}
					}
				},
				{
					threshold: 0.1,
				},
			);

			observer.observe(sliderRef.current);

			return () => {
				if (sliderRef.current) {
					observer.unobserve(sliderRef.current);
				}
			};
		}
	}, [isSliderVisible]);

	return (
		<div
			id="slider"
			ref={sliderRef}
			style={{ height: '100vh', backgroundColor: background }}
		>
			<Swiper
				ref={swiperRef}
				direction={'horizontal'}
				slidesPerView={1}
				spaceBetween={30}
				effect={'creative'}
				speed={1000}
				autoplay={{
					delay: 3000,
					disableOnInteraction: false,
				}}
				creativeEffect={{
					prev: {
						shadow: true,
						translate: [0, 0, -400],
					},
					next: {
						translate: ['100%', 0, 0],
					},
				}}
				navigation={true}
				pagination={{
					clickable: true,
				}}
				modules={[EffectCreative, Pagination, Navigation, Autoplay]}
				className="swiper"
				style={{ height: '100vh' }}
			>
				{sections.map((section, index) => (
					<SwiperSlide key={index} id={section.id}>
						{section.title}
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Slider;
