import React from 'react';
import Lead from '../components/sections/lead';
import Values from '../components/sections/values';
import SecondSlider from '../components/sections/secondSlider';
import bathroom from '../images/bathroom@1x.jpg';
import bathroomWebp from '../images/bathroom@1x.webp';
import leadVideo from '../resources/video/about.mp4';
import AboutFooter from '../components/sections/about-footer';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FirstSlider from '../components/sections/firstSlider';

const AboutCompany = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<html lang="ru" />
				<title>{t('lead.seo.aboutCompany')}</title>
			</Helmet>
			<div className="section" id="top-3">
				<Lead title={t('lead.about-company.mainText')} leadVideo={leadVideo} />
			</div>
			<div className="about-company">
				<section className="phylosophy section" id="second">
					<div className="phylosophy__wrapper wrapper">
						<h2 className="phylosophy__title-text title-text">
							{t('lead.phylosophy.preTitle')}
						</h2>
						<p
							className="phylosophy__lead-text big-text big-text--black"
							dangerouslySetInnerHTML={{ __html: t('lead.phylosophy.text') }}
						/>
						<div className="phylosophy__promo-section">
							<div className="phylosophy__image-wrapper image-wrapper">
								<picture>
									<source type="image/webp" srcSet={bathroomWebp} />
									<img src={bathroom} alt="bathroom" />
								</picture>
							</div>
							<div
								className="phylosophy__text-wrapper"
								dangerouslySetInnerHTML={{
									__html: t('lead.phylosophy.speech'),
								}}
							/>
						</div>
					</div>
				</section>
				<section className="phylosophy phylosophy--no-padding">
					<div className="phylosophy__wrapper wrapper">
						<p
							className="phylosophy__lead-text big-text big-text--black"
							dangerouslySetInnerHTML={{
								__html: t('lead.phylosophy.footerText'),
							}}
						/>
					</div>
				</section>
				<FirstSlider />
				<Values />
				<SecondSlider />
				<AboutFooter />
			</div>
		</>
	);
};

export default AboutCompany;
