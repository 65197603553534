import React from 'react';
import { t } from 'i18next';

const Values = () => {
	return (
		<section className="values" id="values">
			<div className="values__wrapper wrapper">
				<div className="values__text-wrapper">
					<h2 className="slider__title-text title-text title-text--white">
						{t('lead.values.preTitle')}
					</h2>
					<p
						className="slider__lead-text big-text"
						dangerouslySetInnerHTML={{ __html: t('lead.values.bigText') }}
					/>
				</div>
			</div>
		</section>
	);
};

export default Values;
