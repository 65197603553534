import React from 'react';
import { t } from 'i18next';
import Slider from '../utils/slider';

const FirstSlider = () => {
	const sections = [
		{
			id: 'career_slide',
			title: (
				<div className="slider__item slider__item--start slider__item--career">
					<div className="slider__text-wrapper">
						<h2 className="slider__title-text title-text">
							{t('lead.mission.preTitle')}
						</h2>
						<p
							className="slider__lead-text big-text big-text--black"
							dangerouslySetInnerHTML={{ __html: t('lead.mission.bigText') }}
						/>
					</div>
				</div>
			),
		},
		{
			id: 'culture_slide',
			title: (
				<div
					className="slider__item slider__item--culture"
					data-swiper-parallax="20%"
				>
					<div className="slider__text-wrapper">
						<h2 className="slider__title-text title-text">
							{t('lead.culture.preTitle')}
						</h2>
						<p
							className="slider__lead-text big-text big-text--black"
							dangerouslySetInnerHTML={{ __html: t('lead.culture.bigText') }}
						/>
					</div>
				</div>
			),
		},
		{
			id: 'brand_slide',
			title: (
				<div className="slider__item slider__item--brand">
					<div className="slider__text-wrapper">
						<h2 className="slider__title-text title-text">
							{t('lead.brandTarget.preTitle')}
						</h2>
						<p
							className="slider__lead-text big-text big-text--black"
							dangerouslySetInnerHTML={{
								__html: t('lead.brandTarget.bigText'),
							}}
						/>
					</div>
				</div>
			),
		},
	];

	return <Slider sections={sections} />;
};

export default FirstSlider;
