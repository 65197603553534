import React from 'react';
import feedbackMobile from '../../images/feedback--mobile@1x.jpg';
import feedbackMobileWebp from '../../images/feedback--mobile@1x.webp';
import feedback from '../../images/feedback@1x.jpg';
import feedbackWebp from '../../images/feedback@1x.webp';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import toTop from '../../images/to-top.svg';
import ethics from '../../images/ethics@1x.jpg';
import toTopEn from '../../images/to-top-en.svg';
import { useTranslation } from 'react-i18next';

const AboutFooter = () => {
	const { t, i18n } = useTranslation();
	const d = new Date();

	return (
		<div className="about-footer">
			<section className="ethics">
				<div className="ethics__wrapper wrapper">
					<h2 className="ethics__lead-text big-text">
						{t('lead.ethics.preTitle')}
					</h2>
					<p
						className="ethics__title-text title-text title-text--white"
						dangerouslySetInnerHTML={{ __html: t('lead.ethics.bigText') }}
					/>
					<div className="ethics__image-wrapper image-wrapper">
						<img src={ethics} alt="Этический кодекс" />
					</div>
					<div className="ethics__list">
						<div className="ethics__item ethics__item--justice">
							<p
								dangerouslySetInnerHTML={{ __html: t('lead.ethics.ethicsOne') }}
							/>
						</div>
						<div className="ethics__item ethics__item--actions">
							<p
								dangerouslySetInnerHTML={{ __html: t('lead.ethics.ethicsTwo') }}
							/>
						</div>
						<div className="ethics__item ethics__item--mono">
							<p
								dangerouslySetInnerHTML={{
									__html: t('lead.ethics.ethicsThree'),
								}}
							/>
						</div>
						<div className="ethics__item ethics__item--materials">
							<p
								dangerouslySetInnerHTML={{
									__html: t('lead.ethics.ethicsFour'),
								}}
							/>
						</div>
						<div className="ethics__item ethics__item--financial">
							<p
								dangerouslySetInnerHTML={{
									__html: t('lead.ethics.ethicsFive'),
								}}
							/>
						</div>
						<div className="ethics__item ethics__item--international">
							<p
								dangerouslySetInnerHTML={{ __html: t('lead.ethics.ethicsSix') }}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="feedback">
				<div className="feedback__image-wrapper image-wrapper">
					<picture>
						<source
							type="image/webp"
							media="(max-width: 1024px)"
							srcSet={feedbackMobileWebp}
						/>
						<source media="(max-width: 1024px)" srcSet={feedbackMobile} />
						<source type="image/webp" srcSet={feedbackWebp} />
						<img
							src={feedback}
							width="1600"
							height="400"
							alt="Обратный отзыв"
						/>
					</picture>
				</div>
				<div className="feedback__wrapper wrapper">
					<div className="feedback__section">
						<p className="feedback__lead-text big-text big-text--black">
							{t('lead.feedback.title')}
						</p>
						<Link className="feedback__link link-button" to="/contacts/#second">
							{t('lead.feedback.linkText')}
						</Link>
					</div>
					<div className="feedback__section">
						<div className="feedback__anchor-wrapper arrow-down arrow-down--black arrow-down--reverse">
							<AnchorLink className="feedback__moveto" href="#top">
								<img
									src={i18n.language === 'ru' ? toTop : toTopEn}
									width="120"
									height="120"
									alt="Наверх"
								/>
							</AnchorLink>
						</div>
						<p className="feedback__text">Горячая линия Aquaart Group</p>
						<a className="feedback__phone" href="tel:88002002929">
							8 800 200-29-29
						</a>
						<a href="mailto:info@aquaart.ru" className="feedback__mail">
							info@aquaart.ru
						</a>
					</div>
				</div>
			</section>
			<footer className="footer">
				<div className="footer__wrapper wrapper">
					<nav className="footer__nav nav-links nav-links--black">
						<Link className="nav-link" to="/about-company/">
							{t('lead.navigation.aboutCompany')}
						</Link>
						<Link className="nav-link" to="/brands/">
							{t('lead.navigation.ourBrands')}
						</Link>
						<Link className="nav-link" to="/career/">
							{t('lead.navigation.career')}
						</Link>
						<Link className="nav-link" to="/contacts/">
							{t('lead.navigation.contact')}
						</Link>
						<Link className="nav-link" to="/policy/">
							{t('lead.navigation.policy')}
						</Link>
					</nav>
					<p className="footer__copyright">
						© {d.getFullYear()} {t('lead.navigation.copyright')}
					</p>
				</div>
			</footer>
		</div>
	);
};

export default AboutFooter;
